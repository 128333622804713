<template>
    <div class="detail-page mt-5">
        <div class="border-bottom pb-5">
            <div class="container">
                <nav
                    style="--bs-breadcrumb-divider: '\203A'"
                    aria-label="breadcrumb"
                >
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link
                                :to="{ name: 'Home' }"
                                class="text-decoration-none"
                            >
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <router-link
                                :to="{
                                    name: 'PromotionPage',
                                }"
                                class="text-decoration-none"
                            >
                                Promotion
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Detail
                        </li>
                    </ol>
                </nav>
                <!-- BREADCRUMB ENDS -->

                <div
                    class="d-flex align-items-center align-items-lg-start flex-column flex-lg-row w-100 gap-2"
                    v-if="product"
                >
                    <div class="w-100 pvd">
                        <img-slider
                            :images="product.photos"
                            :product_id="product.id"
                            :check_sold_out="checkSoldOut"
                        />
                    </div>
                    <div class="w-100 pvd">
                        <div
                            class="product-info position-relative"
                            style="margin-top:5rem"
                        >
                            <h3>{{ product.title }}</h3>
                            <h5 class="mb-3 fw-bold">
                                {{
                                    addCommaForPrice(
                                        selectedVariant.discounted_price > 0
                                            ? selectedVariant.discounted_price
                                            : selectedVariant.final_price,
                                    )
                                }}
                                MMK
                                <div
                                    class="d-flex"
                                    v-show="selectedVariant.discounted_amount"
                                >
                                    <p
                                        style="text-decoration: line-through;"
                                        class="text-sm"
                                    >
                                        {{
                                            addCommaForPrice(
                                                selectedVariant.final_price,
                                            )
                                        }}
                                        MMK
                                    </p>
                                    <p
                                        class="ms-2 fw-bold"
                                        v-if="selectedVariant.discounted_amount"
                                    >
                                        {{ selectedVariant.discounted_amount }}
                                        {{
                                            selectedVariant.discounted_type == 1
                                                ? '%'
                                                : 'MMK'
                                        }}
                                        OFF
                                    </p>
                                </div>
                            </h5>
                            <div class="row text-center text-md-start">
                                <div style="col-6">
                                    <p class="w-75 mt-3">
                                        အာမခံစိန် အရည်အသွေးမြှင့်
                                        အသန့်နီးပါးကောင်း G color စိန် Quality
                                        တစ်မျိုးကိုသာ အသုံးပြုထားသည့်အတွက်
                                        ယုံကြည်စိတ်ချစွာ ဝယ်ယူနိုင်ပါတယ်
                                    </p>
                                    <div class="my-5">
                                        <div
                                            class="d-flex align-items-center gap-3"
                                        >
                                            <p class="m-0">
                                                {{
                                                    $t(
                                                        'product.detail.quality',
                                                    )
                                                }}:
                                            </p>
                                            <p class="m-0">
                                                {{ product.goldtype.name }}
                                            </p>
                                        </div>
                                        <div
                                            v-if="
                                                product.stones &&
                                                    product.stones.length > 0
                                            "
                                            class="d-flex align-items-center gap-3"
                                        >
                                            <p class="m-0">
                                                Gemstone:
                                            </p>
                                            <p
                                                v-for="(stone,
                                                index) in product.stones"
                                                :key="index"
                                                class="m-0"
                                            >
                                                {{ stone.name }} x
                                                {{ stone.total_quantity }}
                                            </p>
                                        </div>
                                        <div
                                            class="d-flex flex-wrap align-items-center gap-3"
                                        >
                                            <p class="m-0">
                                                {{
                                                    $t('product.detail.weight')
                                                }}:
                                            </p>
                                            <p
                                                v-for="(variant,
                                                index) in product.productvariants"
                                                :key="index"
                                                class="d-flex m-0"
                                            >
                                                <input
                                                    type="radio"
                                                    :name="variant.name"
                                                    :id="variant.id"
                                                    autocomplete="off"
                                                    :checked="
                                                        variant.id ==
                                                            selectedVariant.id
                                                    "
                                                    :value="variant"
                                                    v-model="selectedVariant"
                                                />
                                                <label :for="variant.id">
                                                    <span>
                                                        {{
                                                            variant.name.split(
                                                                '/',
                                                            )[0]
                                                        }}
                                                    </span>
                                                </label>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-center justify-content-md-start"
                            >
                                <div class="add-to-cart">
                                    <template v-if="hasCart">
                                        <router-link
                                            :to="{
                                                name: 'ProductList',
                                                query: {
                                                    category_id:
                                                        product.sub_category
                                                            .category.id,
                                                    filter_tool:
                                                        product.sub_category
                                                            .name,
                                                    type: 'category',
                                                    id: product.sub_category.id,
                                                },
                                            }"
                                            class="btn btn--lg ms-2 mb-2"
                                        >
                                            Continue Shopping
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'ShoppingBag' }"
                                            class="btn btn--lg ms-2 mb-2"
                                        >
                                            Checkout
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        <button
                                            v-if="!checkSoldOut"
                                            class="
                                                btn
                                                add-to-shopping-bag
                                                btn--lg
                                                w-75
                                            "
                                            :disabled="in_stock == 0"
                                            :class="{
                                                'is-disabled': in_stock == 0,
                                            }"
                                            @click="insertToCart(false)"
                                        >
                                            {{
                                                $t(
                                                    'product.detail.addToCartButton',
                                                )
                                            }}
                                        </button>

                                        <button
                                            v-if="!checkSoldOut"
                                            class="btn my-2 btn--lg w-75"
                                            :disabled="in_stock == 0"
                                            :class="{
                                                'is-disabled': in_stock == 0,
                                            }"
                                            @click="insertToCart(true)"
                                        >
                                            {{ $t('product.detail.buyNow') }}
                                        </button>
                                    </template>
                                    <div
                                        :class="[
                                            hasCart || checkSoldOut
                                                ? 'w-100'
                                                : 'w-75',
                                            'd-flex justify-content-center',
                                        ]"
                                    >
                                        <button
                                            v-if="
                                                !this.hasInWishList(
                                                    this.product.id,
                                                )
                                            "
                                            class="btn"
                                            style="background:none; color:#333;"
                                            @click="addToFavourite"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'heart']"
                                            />
                                            <span
                                                style="text-decoration:underline"
                                                >Add to wishlist</span
                                            >
                                        </button>
                                        <button
                                            v-else
                                            class="btn"
                                            style="background:none; color:#333;"
                                            disabled
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'heart']"
                                            />
                                            <span>In wishlist</span>
                                        </button>
                                    </div>
                                    <div id="shoppingBagModal">
                                        <!-- Modal-->
                                        <transition
                                            @enter="startTransitionModal"
                                            @after-enter="endTransitionModal"
                                            @before-leave="endTransitionModal"
                                            @after-leave="startTransitionModal"
                                        >
                                            <div
                                                class="modal fade"
                                                style="background: #0000001A"
                                                v-show="showModal"
                                                ref="modal"
                                                @click.self="showModal = false"
                                            >
                                                <div
                                                    class="modal-dialog"
                                                    role="document"
                                                    style="margin-top: 23rem"
                                                >
                                                    <div
                                                        class="modal-content"
                                                        style="border: 1px solid #0000000D; border-radius: 10px;"
                                                    >
                                                        <div
                                                            class="modal-body p-2"
                                                        >
                                                            <div
                                                                class="d-flex flex-column align-items-center"
                                                            >
                                                                <font-awesome-icon
                                                                    icon="check-circle"
                                                                    style="color:#3CB207;width:1.5rem !important; height:1.5rem !important;margin-top:0.5rem"
                                                                />
                                                                <!-- <span
                                                                    class="modal-title"
                                                                    v-if="
                                                                        !hasCart
                                                                    "
                                                                >
                                                                    Already
                                                                    Added to
                                                                    Shopping Bag
                                                                </span> -->
                                                                <span
                                                                    class="modal-title fw-bold"
                                                                >
                                                                    {{
                                                                        modalMsg
                                                                    }}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style="border-top:1px solid #0000000D;margin:0.3rem -0.5rem"
                                                            ></div>
                                                            <div
                                                                class="d-flex align-items-center justify-content-between"
                                                            >
                                                                <router-link
                                                                    :to="{
                                                                        name:
                                                                            'ProductList',
                                                                        query: {
                                                                            category_id:
                                                                                product
                                                                                    .sub_category
                                                                                    .category
                                                                                    .id,
                                                                            filter_tool:
                                                                                product
                                                                                    .sub_category
                                                                                    .name,
                                                                            type:
                                                                                'category',
                                                                            id:
                                                                                product
                                                                                    .sub_category
                                                                                    .id,
                                                                        },
                                                                    }"
                                                                    class="text-dark text-decoration-none"
                                                                >
                                                                    <span
                                                                        >Continue
                                                                        Shopping</span
                                                                    >
                                                                </router-link>
                                                                <router-link
                                                                    :to="{
                                                                        name:
                                                                            'ShoppingBag',
                                                                    }"
                                                                    class="d-flex align-items-center text-dark text-decoration-none px-2"
                                                                    style="background: #F6F6F6; border-radius: 30px;opacity: 1;"
                                                                >
                                                                    <span
                                                                        >View
                                                                        bag</span
                                                                    >
                                                                    <font-awesome-icon
                                                                        icon="chevron-right"
                                                                        style="padding:0.1rem"
                                                                    />
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>
                                        <div
                                            class="modal-backdrop fade d-none"
                                            ref="backdrop"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="position-absolute d-flex flex-column align-items-center gap-1"
                                style="top:20px;right:10px"
                            >
                                <p class="m-0">Share</p>
                                <ShareNetwork
                                    network="facebook"
                                    :url="
                                        `www.pyaesoneshin.com/products/show/${product.slug}`
                                    "
                                    :title="product.title"
                                    :description="product.description"
                                    :quote="product.title"
                                    hashtags="pss"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'share-alt']"
                                        style="width:1.5rem;height:1.5rem;color:#fff;padding:0.3rem;background:#3CB207;border-radius:50%"
                                    />
                                </ShareNetwork>
                                <ShareNetwork
                                    network="facebook"
                                    :url="
                                        `www.pyaesoneshin.com/products/show/${product.slug}`
                                    "
                                    :title="product.title"
                                    :description="product.description"
                                    :quote="product.title"
                                    hashtags="pss"
                                >
                                    <font-awesome-icon
                                        :icon="['fab', 'facebook-f']"
                                        @open="addPoint"
                                        style="width:1.5rem;height:1.5rem;color:#fff;padding:0.3rem;background:blue;border-radius:50%;"
                                    />
                                </ShareNetwork>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="notifyModal"
                    class="modal notifyModal"
                    tabindex="-1"
                    aria-labelledby="notifyModalLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body">
                                <p class="ms-1">
                                    This product has added to wish list.
                                </p>
                                <div class="text-end">
                                    <a
                                        href="javascript:void(0)"
                                        class="me-2 text-decoration-none fw-bold"
                                        @click="notifyModal = false"
                                    >
                                        CONTINUE SHOPPING
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex flex-column flex-lg-row justify-content-between my-5"
                >
                    <div class="w-100 py-3" style="border-top:1px solid #333">
                        <p>၂နှစ်အောက် ပြန်လဲ/ပြန်သွင်း</p>
                        <ul style="padding-left:1rem">
                            <li>
                                <small>
                                    စိန်ထည်ပစ္စည်းများ တန်ဖိုးမြင့် ပြန်လဲလိုပါက
                                    ဒဲ့ 0%<br />
                                    တန်ဖိုးနိမ့်လဲလိုပါက - 4%
                                </small>
                            </li>
                            <li>
                                <small>
                                    2
                                    နှစ်အတွင်းစိန်ထည်ပစ္စည်းများပြန်သွင်းလိုပါက
                                    - 8% ဖြင့်အချိန်မရွေးအာမခံဖြင့် ပြန်လည်ဝယ်ယူ
                                    ပေးပါတယ်ရှင့်
                                </small>
                            </li>
                        </ul>
                    </div>

                    <div class="w-100 py-3" style="border-top:1px solid #333">
                        <p>အမြတ်ရဖို့သေချာနေတာ ပြည့်စုံသျှင်ပါ</p>
                        <ul style="padding-left:1rem">
                            <li>
                                <small>
                                    2 နှစ်နှင့်အထက် အလဲ 2% အမြတ် အရောင်း 2%
                                    အမြတ်
                                </small>
                                <small>
                                    3 နှစ်နှင့်အထက် အလဲ 4% အမြတ် အရောင်း 4%
                                    အမြတ်
                                </small>
                                <small>
                                    4 နှစ်နှင့်အထက် အလဲ 6% အမြတ် အရောင်း 6%
                                    အမြတ်
                                </small>
                                <small>
                                    5 နှစ်နှင့်အထက် အလဲ 8% အမြတ် အရောင်း 8%
                                    အမြတ်
                                </small>
                                <small>
                                    6 နှစ်နှင့်အထက် အလဲ 10% အမြတ် အရောင်း 10%
                                    အမြတ်
                                </small>
                            </li>
                            <li>
                                <small>
                                    စိန်ထည်ပစ္စည်းများ အရောင်တင် Free (၁)ကြိမ်
                                </small>
                                <small>
                                    စိန်ပြုတ်က (၃၀စီး မှ ၁၀၀ စီး) အထိ Free
                                    (၁)ကြိမ်
                                </small>
                            </li>
                        </ul>
                    </div>

                    <div class="w-100 py-3" style="border-top:1px solid #333">
                        <p>ပို့ခ အခမဲ့ အိမ်ရောက်ငွေချေ</p>
                        <ul style="padding-left:1rem">
                            <li>
                                <small>
                                    ရန်ကုန်မြို့တွင်း (၂)ရက်အတွင်း ပို့ခ အခမဲ့
                                    အိမ်အရောက်ငွေချေ
                                    စနစ်ဖြင့်ပို့ဆောင်ပေးနေပါပြီ
                                </small>
                            </li>
                            <li style="list-style:none;">
                                <small class="pe-3">delivery free</small>
                                <small class="pe-3">cash on delivery</small>
                                <small class="pe-3">KBZ Pay</small>
                                <small class="pe-3">KBZ Banking</small>
                                <small class="pe-3">AYA Pay</small>
                                <small class="pe-3">Wave Money</small>
                                <small class="pe-3">CB Pay</small>
                                <small class="pe-3">AYA Banking</small>
                                <small>Yoma Mobile Banking</small>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- others -->
        <same-product />
        <our-store />
        <main-footer />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Footer from '../home/Footer.vue';
import OurStore from '../home/OurStore.vue';
import ImgSlider from './ImgSlider';
import SameProduct from './SameProduct.vue';
import CommaPrice from '@/mixins/CommaPrice';
import AddToCart from '@/mixins/AddToCart';
import AddToWishlist from '@/mixins/AddToWishlist';

// apollo query
import ProductDetail from '@/graphql/queries/product/ProductDetail.gql';
import AddPointFbShare from '../../graphql/queries/point/AddPointFbShare.gql';

export default {
    mixins: [CommaPrice, AddToCart, AddToWishlist],

    components: {
        'img-slider': ImgSlider,
        'same-product': SameProduct,
        'our-store': OurStore,
        'main-footer': Footer,
    },

    data() {
        return {
            cartCount: 1,
            product_variants: null,
            in_stock: 0,
            // select_option_id: null,
            select_option: {},
            option_pairs: null,
            // price: 0,
            discounted_price: 0,
            gold_weight_gram: 0,
            gold_weight_gram_original: 0,
            show_alert: false,
            checkSoldOut: false,
            notifyModal: false,
            // hasCart: false,
            selectedVariant: {
                id: null,
                final_price: 0,
                final_price_in_different_currency: 0,
                total_in_stock: 0,
                gold_weight_kyat: 0,
                gold_weight_pae: 0,
                gold_weight_ywe: 0,
                gold_weight_gram: 0,
                gold_weight_gram_original: 0,
                discounted_price: 0,
            },
            showModal: false,
            modalMsg: 'Added to shopping bag successfully',
        };
    },

    mounted() {
        this.getProduct();
    },

    watch: {
        $route() {
            this.getProduct();
        },
    },

    computed: {
        ...mapGetters({
            product: 'product/getProduct',
            isLoggedIn: 'auth/isLoggedIn',
            carts: 'cart/getCarts',
        }),
        hasCart() {
            return (
                this.carts.find(
                    cart => cart.product_variant_id === this.selectedVariant.id,
                ) ?? false
            );
        },
    },

    methods: {
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
        addToFavourite() {
            if (this.isLoggedIn) {
                // if (!this.hasInWishList(this.product.id)) {
                this.notifyModal = true;
                this.addToWish(this.product.id);
                // }
            } else {
                this.$router.push({
                    name: 'Login',
                });
            }
        },

        incCount() {
            if (this.cartCount < this.in_stock) {
                this.cartCount++;
            } else {
                this.cartCount;
            }
        },

        descCount() {
            if (this.cartCount > 1) {
                this.cartCount--;
            } else {
                this.cartCount = 1;
            }
        },

        getProduct() {
            this.$apollo
                .query({
                    query: ProductDetail,
                    variables: {
                        slug: this.$route.params.slug,
                    },
                })
                .then(response => {
                    this.$store.commit(
                        'product/ADD_PRODUCT',
                        response.data.product,
                    );
                    this.in_stock = response.data.product.productvariants.reduce(
                        (total, res) => {
                            return (total += parseInt(res.total_in_stock));
                        },
                        0,
                    );
                    this.selectedVariant =
                        this.product.productvariants.find(
                            pv => pv.total_in_stock > 0,
                        ) ?? this.product.productvariants[0];
                    this.checkSoldOut = this.product.productvariants.every(
                        variant => {
                            return variant.total_in_stock <= 0;
                        },
                    );
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        insertToCart(isBuyNow = false) {
            this.addCart({
                id: this.product.id,
                slug: this.product.slug,
                title: this.product.title,
                category_id: this.product.sub_category.category.id,
                gold_type: this.product.goldtype.name,
                quantity: this.cartCount,
                photo: this.product.photos[0].thumbnail_url,
                product_variant_id: this.selectedVariant.id,
                product_variant_name: this.selectedVariant.name,
                final_price: this.selectedVariant.final_price,
                discounted_type: this.selectedVariant.discounted_type,
                discounted_price: this.selectedVariant.discounted_price,
                final_price_in_different_currency: this.selectedVariant
                    .final_price_in_different_currency,
                gold_weight_gram: this.selectedVariant.gold_weight_gram,
            });
            this.showModal = true;
            if (isBuyNow) {
                this.$router.push({
                    name: 'ShoppingBag',
                });
            }
        },

        addPoint() {
            this.$apollo
                .query({
                    query: AddPointFbShare,
                })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/components/cartCounter.scss';
@import '@/sass/components/checkbox.scss';

.atc-alert-close {
    z-index: unset !important;
}

.notifyModal {
    display: block;
    background: #00000085;
    a {
        color: #000;
    }
}

.product-info {
    .product-variants {
        .variant-detail {
            .option-lists {
                display: flex;
                flex-wrap: wrap;

                .option {
                    margin: 0 0.75rem 0.75rem 0;
                }

                label {
                    font-weight: 300;
                    padding: 0.25rem 1rem;
                }

                .option input:not(:checked) ~ label {
                    background: transparent;
                    color: var(--color-black);
                }
            }
        }
    }

    .detail-link a {
        border-bottom: 1px solid var(--color-black);
        color: var(--color-black);
        font-size: 19px;
        text-decoration: none;

        &:after {
            content: 'next';
            font-family: swiper-icons;
            font-size: 13px;
            line-height: 1;
            padding-left: 10px;
            text-transform: none;
        }
    }

    .add-to-cart {
        button {
            padding: 0.7rem 2.5rem !important;
        }

        .is-disabled {
            display: none;
            background-color: #6c757d !important;
            border: 1px solid #6c757d !important;
            cursor: not-allowed;

            &:hover {
                color: #fff !important;
            }
        }
    }

    .cartCounter,
    .add-to-cart {
        margin-bottom: 0.75rem;
    }

    ul {
        // max-width: 70%;

        li {
            // width: 33.3333%;
            font-size: 0.9rem;
            margin-bottom: 1rem;
            padding: 0 0.75rem;

            .dollar {
                // background-color: #67a00e;
                background-color: var(--color-green);
                border-radius: 50%;
                color: #fff;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
            }

            &:not(:last-child) {
                border-right: 1px solid var(--color-gray-dark);
            }
        }
    }
}

.disable-check {
    display: none;
    pointer-events: none;
    background: #6c757d !important;
    border: none;
    color: #fff !important;
}
.add-to-shopping-bag {
    color: black;
    border: 1px solid #0000001a;
    border-radius: 5px;
    background: white;
    &:hover {
        background: #3cb207;
        color: white;
        border: 1px solid #3cb207;
    }
}
@media (min-width: 991.9px) {
    .pvd {
        width: 50% !important;
    }
}
.modal-dialog {
    max-width: 20rem;
    margin: 1.75rem auto;
}
</style>
