<template>
    <product-detail />
</template>

<script>
import ProductDetail from '../../components/products/Detail';

export default {
    components: {
        'product-detail': ProductDetail,
    },
};
</script>
